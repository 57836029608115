<template>
  <div class="wrap-bottom">
    <div class="table-header">
      <div class="theader-left">
        <span>作废项目</span>
      </div>

      <div class="theader-right">
        <el-select filterable
                   v-model="selectOption"
                   placeholder="请选择业务类型"
                   @change="getywItem">
          <el-option v-for="item in ywlxList"
                     :key="item.Id"
                     :label="item.label"
                     :value="item.Id">
          </el-option>
        </el-select>
        <div class="wrap-iv">
          <el-input v-model.trim="inputValue"
                    placeholder="请输入项目名称,项目代码" />
        </div>

        <el-button @click="handleQuery"
                   size="medium">查询</el-button>
        <el-button @click="handleReset"
                   style="margin-left: 0px"
                   size="medium">重置</el-button>
      </div>
    </div>
    <PublicTable ref="publicTable">
      <template #td_ZBH="scope">
        <span class="tes" @click="openTask(scope.$index, scope.row)">{{
          scope.row.ZBH
        }}</span>
      </template>
      <template #td_ID="scope">
        <el-button
          size="mini"
          type="text"
          @click="delCancel(scope.$index, scope.row)"
          >恢复</el-button
        >
      </template>
    </PublicTable>
  </div>
  <el-dialog v-model="DialogVisible"
             width="30%"
             title="确认将此项目恢复？">
    <el-form>
      <span>输入“确认恢复”恢复所选中的项目</span>
      <el-form-item label="确认恢复"
                    :label-width="formLabelWidth">
        <el-input v-model="CancelRemark"
                  autocomplete="off"
                  type="text"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="DialogVisible = false">取消</el-button>
        <el-button type="primary"
                   @click="DelTaskCancel()">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ElMessage } from 'element-plus'
import PublicTable from '@/components/table/PublicTable.vue'
import mgr from '@/services/security.js'
export default {
  components: {
    PublicTable,
  },
  data() {
    return {
      ywlxList: [{ Id: '', label: '全部', icon: 'box', num: 0 }],
      inputValue: '', //搜索的内容
      rowData: [], //表头,
      queryId: 'Cancel_list',
      selectOption: '', //当前选项
      userid: '',
      DialogVisible: false,
      TaskId: '',
      InstanceId: '',
      flowApiUrl: this.$appConfig.apiUrls.flowApi,
      ywxtApiUrl: this.$appConfig.apiUrls.ywxtApi,
      CancelRemark: '',
    }
  },
  methods: {
    //搜索
    handleQuery() {
      this.whereconditions()
    },
    whereconditions() {
      this.queryId = 'Cancel_list'
      let conditions = [
        {
          Key: 'xmmc,XMDM',
          Value: '%' + this.inputValue + '%',
          IsCondition: true,
          Operator: 'like',
          LogicalOperator: 'and',
        },
      ]

      this.$refs.publicTable.getLoadData(this.queryId, conditions)
    },
    //重置
    handleReset() {
      this.inputValue = ''
      //this.$refs.ywMenu.activeIndex = '0'
      this.rendertable()
    },
    getworkflowlist() {
      let me = this
      return this.$ajax
        .get(
          me.$appConfig.apiUrls.flowApi +
            '/api/WorkFlow/GetWorkFlowList?userId=' +
            me.userid
        )
        .then(function (response) {
          if (response.data.IsSuccess) {
            me.ywlxList = [{ Id: '', label: '全部', icon: 'box', num: 4 }]
            me.selectOption = me.ywlxList[0].label
            for (var key in response.data.Data) {
              me.ywlxList.push({
                Id: key,
                label: response.data.Data[key],
                icon: 'box',
                num: 4,
              })
            }
          } else {
            console.log(response.data.errorMessage)
          }
        })
    },
    rendertable() {
      this.selectOption = ''
      let me = this
      let conditions = [
        {
          Key: 'wft.Status',
          Value: me.activeIndex,
          IsCondition: true,
          Operator: '=',
          LogicalOperator: 'and',
        },
      ]
      me.$refs.publicTable.getLoadData(me.queryId, conditions)
    },
    openTask(index,row) {
      let res = JSON.stringify(row)
      this.$router.push({ path: '/bjsx/info', query: { row: res } })
    },
    delCancel(index,row) {
      let res = JSON.stringify(row)
      console.log(res)
      this.TaskId = row.taskid
      this.InstanceId = row.Id
      this.DialogVisible = true
    },
    async DelTaskCancel() {
      let me = this
      if (
        me.CancelRemark.trim() == '' ||
        me.CancelRemark.trim() != '确认恢复'
      ) {
        ElMessage({
          type: 'info',
          message: '请填写确认信息',
        })
      } else {
        await this.$ajax
          .post(
            me.ywxtApiUrl + '/api/YW/DelTaskCancel?InstanceId=' + me.InstanceId
          )
          .then(function (response) {
            if (response.data.IsSuccess) {
              me.DialogVisible = false
              me.SolutionCancelTask()
            } else {
              //alert(response.data.ErrorMessage)
              ElMessage({
                type: 'info',
                message: '恢复项目失败：' + response.data.ErrorMessage,
              })
            }
          })
      }
    },
    async SolutionCancelTask() {
      const me = this
      await this.$ajax
        .post(
          this.flowApiUrl +
            '/api/WorkFlowExecute/SolutionCancelTask?taskId=' +
            this.TaskId
        )
        .then(function (response) {
          if (response.data.IsSuccess) {
            ElMessage({
              type: 'info',
              message: '恢复成功，请到办理列表查询！',
            })
            me.refreshTable()
          } else {
            ElMessage({
              type: 'error',
              message: response.data.ErrorMessage,
            })
          }
        })
        .catch(function (error) {
          console.log(error)
          ElMessage({
            type: 'error',
            message: error,
          })
        })
    },
    async getuser() {
      let user = await mgr.getUser()
      this.userid = user.profile.sub
    },
    refreshTable() {
      const fid = this.$route.query.fid
      if (fid && this.ywlxList.some((ywlx) => ywlx.Id == fid)) {
        this.selectOption = fid
        this.getywItem(fid)
      } else {
        this.rendertable()
      }
    },
  },
  async mounted() {
    this.getuser()
    await this.getworkflowlist()
    this.refreshTable()
  },
  watch: {
    $route() {
      if (this.$route.name === 'cancel') {
        this.refreshTable()
      }
    },
  },
}
</script>

<style scoped>
.table-header {
  padding: 10px;
  overflow: hidden;
  box-shadow: 0 0 20px rgb(0 0 0 / 10%);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrap-iv {
  display: inline-block;
  margin-right: 5px;
}

.clickable {
  color: #3c8dbc;
}

.clickable:hover {
  text-decoration: underline;
}
</style>